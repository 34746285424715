// Number of free words the user can generate for free.
export const FREE_MONTHLY_GEN_WORDS = 500

// Number of words to generated before showing the cycling guide
export const SHOW_CYCLING_GUIDE_WORDS = 30

// Number of words accepted until we only show tooltip upon click instead of hover
export const SHOW_TOOLTIP_ON_CLICK = 200

export const GLOBAL = 'GLOBAL'

// Daily free words to be given to users
export const FREE_DAILY_WORDS = 200
