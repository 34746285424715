import { DefaultMantineColor, MantineProvider, Tuple } from '@mantine/core'
import { ReactNode } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import { RecursiveKeyValuePair, ResolvableTo } from 'tailwindcss/types/config'
import twConfig from './../tailwind.config'

// The following line is needed to declare typescript types for custom colors
declare module '@mantine/core' {
    export interface MantineThemeColorsOverride {
        colors: Record<
            | 'primary'
            | 'secondary'
            | 'slate'
            | 'gray'
            | 'zinc'
            | 'neutral'
            | 'stone'
            | 'red'
            | 'orange'
            | 'amber'
            | 'yellow'
            | 'lime'
            | 'green'
            | 'emerald'
            | 'teal'
            | 'cyan'
            | 'sky'
            | 'blue'
            | 'indigo'
            | 'violet'
            | 'purple'
            | 'fuchsia'
            | 'pink'
            | 'rose'
            | DefaultMantineColor,
            Tuple<string, 10>
        >
    }
}

const tailwindConfig = resolveConfig(twConfig)

const getFirstChar = (value: string) => (value.length > 0 ? value[0] : '')

const mapToMantineColor = (color: Record<string, string>) =>
    Object.entries(color).reduce(
        (hash: Record<string, string>, [key, value]) => {
            // Shade 50 maps to 0 in mantine
            const newKey = key === '50' ? '0' : getFirstChar(key)
            hash[newKey] = value
            return hash
        },
        {}
    )

/**
 * This function maps tailwind colors to mantine colors
 * @param colors Tailwind colors
 * @returns
 */
const mapToMantineColors = (
    colors: ResolvableTo<RecursiveKeyValuePair<string, string>>
) =>
    Object.entries(colors).reduce(
        (
            hash: Record<string, Record<string, string> | string>,
            [key, value]
        ) => {
            if (typeof value === 'object') {
                hash[key] = mapToMantineColor(value)
            } else {
                hash[key] = value
            }
            return hash
        },
        {}
    )

let tailwindColors = {}
if (tailwindConfig.theme?.colors) {
    tailwindColors = mapToMantineColors(tailwindConfig.theme.colors)
}

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
                colors: {
                    ...tailwindColors
                },
                primaryColor: 'primary',
                primaryShade: 6,
                colorScheme: 'light',
                fontFamily: 'Inter, sans-serif',
                components: {
                    Container: {
                        classNames: {
                            root: 'm-0'
                        }
                    },
                    Button: {
                        classNames: {
                            root: 'rounded-md'
                        }
                    },
                    Anchor: {
                        classNames: {
                            root: 'hover:no-underline'
                        }
                    },
                    Navbar: {
                        classNames: {
                            root: 'bg-transparent'
                        }
                    },
                    Header: {
                        classNames: {
                            root: 'border-none'
                        }
                    },
                    Input: {
                        classNames: {
                            input: 'border-slate-300 rounded-md placeholder:text-gray-500'
                        }
                    },
                    TextInput: {
                        classNames: {
                            label: 'text-sm font-medium text-gray-700',
                            error: 'first-letter:capitalize'
                        }
                    },
                    PasswordInput: {
                        classNames: {
                            label: 'font-normal'
                        }
                    },
                    Notification: {
                        classNames: {
                            root: 'z-[var(--z-toast)]',
                            closeButton:
                                'absolute right-0 top-0 text-gray-400 hover:bg-white'
                        }
                    },
                    Modal: {
                        classNames: {
                            modal: 'rounded-lg'
                        }
                    },
                    Card: {
                        classNames: {
                            root: 'rounded-lg'
                        }
                    }
                }
            }}
        >
            {children}
        </MantineProvider>
    )
}
