import { createContext } from 'mobx-keystone'

/**
 * Typings for analytics implementations
 */
export type TrackParam = (
    | {
          anonymousId: string
      }
    | {
          userId: string
      }
) & {
    event: string
    properties: { [_: string]: any }
    context?: { [_: string]: any }
}

/**
 * An interface for an object that can handle analytics tracking
 */
export interface Analytics {
    track(data: TrackParam): Promise<void>

    /**
     * Identifies the user (logs in) for Segment
     * @param userId
     * @param traits
     */
    identify(userId: string, traits?: any): Promise<void>

    /**
     * Resets the user and traits (logout)
     */
    reset(): void
}

/**
 * Holds the analytics context (dependency injection).
 */
export const analyticsCtx = createContext<Analytics>()

/**
 * Mock analytics for dev
 */
export const mockAnalytics: Analytics = {
    async track(data) {
        console.info('Segment mock:', 'track', data)
    },
    async identify(userId, traits?) {
        console.info('Segment mock:', 'identify', userId, traits)
    },
    async reset() {
        console.info('Segment mock:', 'reset')
    }
}
