import { ContentType, Tone } from '@jenni/common/src/doc/metadata'
import { computed, reaction } from 'mobx'
import {
    idProp,
    model,
    Model,
    modelAction,
    ModelData,
    prop
} from 'mobx-keystone'
import stripe from 'stripe'
import { analyticsCtx } from '../analytics'
import { FREE_MONTHLY_GEN_WORDS } from '../constants'
import { JSONTimestamp, timestampTransform } from '../firebase/timestamp'

interface DefaultDocSetup {
    contentType: ContentType | null
    tone: Tone | null
}
/**
 * Firestore document for user data.
 * This holds custom user data that we store.
 * Basic user data is stored by Firebase Auth, accessible via `AuthModel.user`.
 */
@model('FSUserData')
export class FSUserData extends Model({
    id: idProp,
    // Name of user
    name: prop<string | undefined>(),
    // Email of the user
    email: prop<string | undefined>(undefined),
    // If true, indicates the user has completed the onboarding modal
    onboarded: prop<true | undefined>(undefined).withSetter(),
    // If true, the user has disabled the tutorial guide (e.g., for suggestions)
    disableGuide: prop<true | undefined>(undefined).withSetter(),
    // Total number of words the user has generated during their lifetime. This never resets.
    generatedCountLifetime: prop<number>(0).withSetter(),
    transformedCountLifetime: prop<number>(0).withSetter(),
    // Number of words user has generated during this subscription period
    generatedCount: prop<number>(0).withSetter(),
    // Limit of how many words the user can generate at this instance
    generationWordLimit: prop<number>(
        () => FREE_MONTHLY_GEN_WORDS
    ).withSetter(),
    dailyWords: prop<number | undefined>().withSetter(),
    // Number of words carried over from prior plans that are counted in generationWordLimit
    carryOverWords: prop<number>(0).withSetter(),
    // Default doc setup options for user
    defaultDocSetup: prop<DefaultDocSetup>(() => ({
        contentType: null,
        tone: null
    })).withSetter(),
    // Stripe ID
    stripeId: prop<string | null>(null),
    // Stripe subscription object
    subscription: prop<stripe.Subscription | null>(null),
    // Current subscription schedule, if it exists
    subscriptionSchedule: prop<stripe.SubscriptionSchedule | null>(null),
    // Last time our server received a subscription update event
    stripeLastUpdated: prop<JSONTimestamp | null>(null).withTransform(
        timestampTransform
    ),
    // This is an a/b flag for testing daily words
    testDailyWords: prop<boolean | undefined>().withSetter(),
    // TODO: LEGACY. Remove once all subscribers are migrated. (1 month after Oct 22, 2022)
    subscriptionStatus: prop<string | null>(null)
}) {
    onAttachedToRootStore() {
        const analytics = analyticsCtx.get(this)
        // Update analytics if user's properties change
        if (analytics)
            return reaction(
                () => [
                    this.id,
                    this.generatedCountLifetime,
                    this.generatedCount,
                    this.generationWordLimit,
                    this.subscription
                ],
                () => {
                    if (this.id) {
                        analytics.identify(this.id, {
                            generatedCountLifetime: this.generatedCountLifetime,
                            generatedCount: this.generatedCount,
                            generationWordLimit: this.generationWordLimit,
                            subscriptionStatus:
                                this.subscription?.status ||
                                this.subscriptionStatus,
                            plan: this.subscription?.items.data[0]?.price.id
                        })
                    }
                },
                { delay: 1000, name: 'trackUserData' }
            )
        return undefined
    }

    /** True if the user's generation limit exceeded */
    @computed
    get generationLimitExceeded() {
        return this.generatedCount >= this.generationWordLimit
    }

    /** Has remaining daily words */
    @computed
    get hasDailyWords() {
        return this.dailyWords && this.dailyWords > 0
    }

    /** Has remaning words from both monthly quota and daily words */
    @computed
    get hasWords() {
        return !this.generationLimitExceeded || this.hasDailyWords
    }

    @computed
    get isSubscribed() {
        return (
            this.subscription?.status === 'active' ||
            this.subscriptionStatus === 'active'
        )
    }

    @modelAction
    consumeWords(count: number) {
        if (this.hasDailyWords && this.dailyWords) {
            this.dailyWords = this.dailyWords - count
        } else {
            this.generatedCount = this.generatedCount + count
        }
    }
}

// Typing
export type IFSUserData = ModelData<FSUserData>
