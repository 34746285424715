/** @type {import('tailwindcss').Config} */
module.exports = {
    content: ['./pages/**/*.{js,jsx,ts,tsx}', './lib/**/*.{js,jsx,ts,tsx}'],
    theme: {
        fontFamily: {
            sans: ['Inter', 'sans-serif']
        },
        extend: {
            colors: {
                white: '#FFFFFF',
                primary: {
                    50: '#F2F3FA',
                    100: '#E1E2F6',
                    200: '#CCCFF3',
                    300: '#A6ABF1',
                    400: '#8087F6',
                    500: '#616AF3',
                    600: '#535CEA',
                    700: '#444DD7',
                    800: '#323DD6',
                    900: '#1722BE'
                },
                secondary: {
                    50: '#FAFAFC',
                    100: '#F2F1F9',
                    200: '#E8E5FA',
                    300: '#D0CBF4',
                    400: '#9C93D9',
                    500: '#847AC7',
                    600: '#746CAC',
                    700: '#5A548B',
                    800: '#3E376F',
                    900: '#201951'
                }
            }
        }
    },
    plugins: [
        ({ addVariant }) => {
            // TODO: Remove once bootstrap is removed
            // Add child styles: https://stackoverflow.com/questions/67119992/how-to-access-all-the-direct-children-of-a-div-in-tailwindcss
            addVariant('child-hover', '& > *:hover')
        }
    ]
}
