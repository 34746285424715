// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
    enabled: ['prod', 'stage'].includes(process.env.APP_STAGE),
    environment: process.env.APP_STAGE,
    dsn:
        SENTRY_DSN ||
        'https://a71be7b4c11249d891625fce66609c7d@o38499.ingest.sentry.io/5431460',
    // Adjust this value in production, or use tracesSampler for greater control
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
})
