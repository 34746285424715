import {
    collection,
    doc,
    DocumentData,
    DocumentReference,
    DocumentSnapshot,
    getFirestore,
    increment,
    onSnapshot,
    query,
    QueryConstraint,
    QuerySnapshot,
    setDoc,
    updateDoc
} from 'firebase/firestore'
import { computed } from 'mobx'
import {
    applySnapshot,
    assertIsTreeNode,
    BaseModel,
    fromSnapshot,
    getSnapshot,
    Model,
    model,
    modelAction,
    modelFlow,
    ModelIdProp,
    ModelProps,
    onPatches,
    Patch,
    prop,
    _async,
    _await
} from 'mobx-keystone'
import { firebaseAppCtx } from '.'
import { objectAssign } from '../utils'

/**
 * A MobX model that bi-directionally syncs with a Firestore document.
 * When Firebase changes, the value will be auto-updated.
 * When local value changes, Firebase will be updated accordingly.
 *
 * This is done by observing patches to the `value` object.
 *
 * NOTE: The model must have default values and an id property to store the document ID.
 */

@model('FSSync')
export class FSSync<
    T extends BaseModel<
        // TODO: Use a tigher constraint
        // ModelProps & { id: ModelIdProp | OptionalModelProp<string> },
        ModelProps,
        any,
        any,
        any
    >
> extends Model(<T>() => ({
    // Firebase path
    path: prop<string>(),
    value: prop<T>().withSetter(),
    // True if we synced at least once
    syncedOnce: prop(false),
    // True if the doc eixsts on Firebase
    exists: prop(false)
}))<T> {
    // Used to mark if we're about to sync from firebase to prevent inf patch loop.
    isSnapshotFromFirebase = false

    onAttachedToRootStore() {
        // Sync bidirectionally
        const disposers = [
            onSnapshot(this.fsDoc, this.onSnapshotCallback.bind(this)),
            onPatches(this.value, this.onValuePatch.bind(this))
        ]

        return () => disposers.forEach((d) => d())
    }

    // Sync callback from Firebase to local
    @modelAction
    onSnapshotCallback(snapshot: DocumentSnapshot<T>) {
        // Only permit defined keys to be used for producing snapshot
        const allowedKeys = Object.keys(getSnapshot(this.value))
        this.isSnapshotFromFirebase = true
        const data = snapshot.data() ?? ({} as any)
        const updateObj = allowedKeys
            .map((k) => ({ [k]: data[k] }))
            .reduce(objectAssign, {})

        try {
            applySnapshot(
                this.value,
                getSnapshot(
                    fromSnapshot<T>(
                        parseFirestoreData(
                            updateObj,
                            snapshot.id,
                            this.value.$modelType
                        )
                    )
                )
            )
        } catch (e) {
            console.error('Firebase updateObj:', updateObj)
            throw e
        }
        this.exists = snapshot.exists()
        this.isSnapshotFromFirebase = false
        assertIsTreeNode(this.value)
        this.syncedOnce = true
    }

    // Sync callback from local to Firebase
    @modelFlow
    onValuePatch = _async(function* (this: FSSync<T>, patches: Patch[]) {
        if (this.isSnapshotFromFirebase) return

        // TODO: Will not support timestamps...
        const updateObj = patches
            .map((p) => ({
                [p.path.join('.')]: p.op === 'remove' ? null : p.value
            }))
            .reduce(objectAssign, {})
        yield* _await(setDoc(this.fsDoc, updateObj, { merge: true }))
    })

    @computed
    get fsDoc() {
        const app = firebaseAppCtx.get(this)
        if (app) {
            const firestore = getFirestore(app)
            return doc(firestore, this.path) as DocumentReference<T>
        } else {
            throw new Error('Firebase app is not defined')
        }
    }

    /**
     * Increment the number of a property in the Firestore doc
     * @param count Number to increment
     */
    @modelFlow
    increment = _async(function* (
        this: FSSync<T>,
        key: keyof T,
        count: number
    ) {
        yield* _await(
            updateDoc<any>(this.fsDoc, {
                [key]: increment(count)
            })
        )
    })
}

/**
 * A MobX model that bi-directionally syncs with a Firestore collection.
 * When Firebase changes, the value will be auto-updated.
 * When local value changes, Firebase will be updated accordingly.
 *
 * This is done by observing patches to the `value` object.
 *
 * NOTE: The model must have default values and an id property to store the document ID.
 */

@model('FSCollectionSync')
export class FSCollectionSync<
    T extends BaseModel<ModelProps & { id: ModelIdProp }, any, any, any>
> extends Model(<T>() => ({
    docModelType: prop<string>(),
    // Firebase path
    path: prop<string>(),
    values: prop<T[]>(() => []),
    // True if we synced at least once
    syncedOnce: prop(false)
}))<T> {
    queryConstraints: QueryConstraint[] = []

    // Used to mark if we're about to sync from firebase to prevent inf patch loop.
    isSnapshotFromFirebase = false

    // Maps doc IDs to their data
    docMap = new Map<string, T>()
    // Maps index to doc ID
    docIndex = new Map<number, string>()

    onAttachedToRootStore() {
        // Sync bidirectionally
        const disposers = [
            onSnapshot(this.fsCollection, this.onSnapshotCallback.bind(this))
            // onPatches(this.values, this.onValuePatch.bind(this))
        ]

        return () => disposers.forEach((d) => d())
    }

    // Sync callback from Firebase to local
    @modelAction
    onSnapshotCallback(snapshot: QuerySnapshot) {
        // Only permit defined keys to be used for producing snapshot
        this.isSnapshotFromFirebase = true

        snapshot.docChanges().forEach((c) => {
            // TODO: Check if index shift would be an issue
            // TODO: Should unit test this
            switch (c.type) {
                case 'added':
                    this.values.splice(
                        c.newIndex,
                        0,
                        fromSnapshot<T>(
                            parseFirestoreData(
                                c.doc.data(),
                                c.doc.id,
                                this.docModelType
                            )
                        )
                    )
                    break
                case 'modified':
                    this.values[c.newIndex] = fromSnapshot<T>(
                        parseFirestoreData(
                            c.doc.data(),
                            c.doc.id,
                            this.docModelType
                        )
                    )
                    break
                case 'removed':
                    this.values.splice(c.oldIndex, 1)
                    break
            }
        })
        this.isSnapshotFromFirebase = false
        this.syncedOnce = true
    }

    // Sync callback from local to Firebase
    // @modelFlow
    // *onValuePatch(patches: Patch[]) {
    //     if (this.isSnapshotFromFirebase) return

    // TODO: Implement this
    // const updateObj = patches
    //     .map((p) => ({
    //         [p.path.join('.')]: p.op === 'remove' ? null : p.value
    //     }))
    //     .reduce(objectAssign, {})
    // yield* _await(updateDoc(this.fsCollection, updateObj))
    // }

    @computed
    get fsCollection() {
        const app = firebaseAppCtx.get(this)
        if (app) {
            const firestore = getFirestore(app)
            return query(
                collection(firestore, this.path),
                ...this.queryConstraints
            )
        } else {
            throw new Error('Firebase app is not defined')
        }
    }
}

const parseFirestoreData = (
    updateObj: DocumentData,
    id: string,
    modelType: string
) => {
    // TODO: Not very efficient, but we need to turn data from Firebase into a pure object for MKS.
    // Timestamp objects can disrupt MKS
    return {
        ...JSON.parse(JSON.stringify(updateObj)),
        id: id,
        $modelType: modelType
    } as any
}
