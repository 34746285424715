import { computed } from 'mobx'
import { model, Model, ModelData, prop } from 'mobx-keystone'
import Stripe from 'stripe'

interface StripeTier {
    // Price per unit
    unit_amount_decimal: string | null
    // Max quantity
    up_to: number | null
}
export interface Tier {
    words: number
    price: number
}

/**
 * This is synced by the backend with Stripe
 */
@model('Prices')
export class Prices extends Model({
    id: prop<string | undefined>(undefined),
    data: prop<Stripe.Price[]>(() => [])
}) {
    @computed
    get activePrices() {
        return this.data.filter((p) => p.active)
    }
    @computed
    get monthlyPrice() {
        return this.activePrices.find((p) => p.recurring?.interval === 'month')
    }
    @computed
    get annualPrice() {
        return this.activePrices.find((p) => p.recurring?.interval === 'year')
    }

    @computed
    get monthlyTiers() {
        return this.monthlyPrice?.tiers
            ? getTiers(this.monthlyPrice.tiers)
            : null
    }
    @computed
    get annualTiers() {
        return this.annualPrice?.tiers ? getTiers(this.annualPrice.tiers) : null
    }
}

// Typing
export type IPrices = ModelData<Prices>

function getTiers(stripeTiers: StripeTier[]) {
    const tiers = []
    let curPrice = 0
    let lastTierWords = 0
    for (const tier of stripeTiers) {
        if (tier.unit_amount_decimal === null || tier.up_to === null) continue
        curPrice +=
            Number.parseFloat(tier.unit_amount_decimal) *
            (tier.up_to - lastTierWords)
        lastTierWords = tier.up_to
        tiers.push({ words: tier.up_to, price: Math.ceil(curPrice / 100) })
    }
    return tiers
}
