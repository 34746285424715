import md5 from 'md5'
import { TimedPromiseState } from './../typings'

export function bufferToString(buf: Uint8Array) {
    let binary = ''
    const len = buf.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(buf[i])
    }
    return binary
}

export function stringToUint8Array(str: string): Uint8Array {
    const buf = new ArrayBuffer(str.length)
    const bufView = new Uint8Array(buf)
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i)
    }
    return bufView
}

/**
 * Produces a value between 0 and 1 for A/B split testing.
 * @param seed A deterministic string to draw this random value.
 */
export function randomDecision(seed: string) {
    return parseInt(md5(seed).slice(0, 8), 16) / 0xffffffff
}

/**
 * Wait for some milli-seconds, then resolves the promise.
 * @param time Number of milliseconds to wait for
 * @returns A promise that resolves upon finish waiting.
 */
export const waitMS = async (time: number) =>
    new Promise((resolve) => setTimeout(resolve, time))

export const objectAssign = <T>(a: T, b: T) => Object.assign(a as any, b)

export function shuffleArray<T>(array: T[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
}

/**
 * Finds the top k indices of the array.
 * O(nlog(n)) complexity. Could optimize to linear time.
 * @param arr
 */
export const findTopKIndices = <T>(
    arr: Iterable<T>,
    k: number,
    compareFn: (a: T, b: T) => number
) =>
    Array.from(arr)
        .map((v, k) => ({ v, k }))
        .sort((a, b) => compareFn(a.v, b.v))
        .slice(-k)
        .map((o) => o.k)

/**
 * Combines a list of async iterators into a single one, where elements are yielded as soon as they're ready.
 * @param iterable A list of async generators
 * @returns A new async generator that combines all the provided generators
 */
export async function* combine<T>(
    asyncIterators: AsyncIterator<T>[]
): AsyncGenerator<T> {
    const results = []
    let count = asyncIterators.length
    const never = new Promise(() => {
        return
    })

    function getNext(asyncIterator: AsyncIterator<any>, index: number) {
        return asyncIterator.next().then((result: any) => ({
            index,
            result
        }))
    }
    const nextPromises = asyncIterators.map(getNext)
    try {
        while (count) {
            const { index, result } = await Promise.race(nextPromises)
            if (result.done) {
                ;(nextPromises[index] as any) = never
                results[index] = result.value
                count--
            } else {
                nextPromises[index] = getNext(asyncIterators[index], index)
                yield result.value
            }
        }
    } finally {
        for (const [index, iterator] of asyncIterators.entries())
            if (nextPromises[index] != never && iterator.return != null)
                iterator.return()
        // no await here - see https://github.com/tc39/proposal-async-iteration/issues/126
    }
    return results
}

/**
 * Checks if a timed promise state is pending.
 * @param state Promise state
 * @param timeout Timeout in milliseconds
 * @returns True if the state is pending and has not timed out.
 */
export const isPromiseStatePending = (
    state: TimedPromiseState,
    timeout: number
): boolean =>
    state.fulfilled === undefined &&
    state.rejected === undefined &&
    Date.now() - (state.pending as number) < timeout

const numberedTextRegex = /^(([0-9]+)\)|#([0-9]+)|([0-9]+)\.)\s.*/
/**
 * Extract numbered text with prefix such as 1), #1, 1. from a string
 * @return The number if it exists, otherwise null if it doesn't exist.
 */
export const extractNumberedText = (
    text: string
): { number: number; prefix: string; format: (_: string) => string } | null => {
    const matches = text.trimStart().match(numberedTextRegex)
    if (!matches) return null
    // Parse any of the possible match groups
    return {
        number: Number.parseInt(matches[2] || matches[3] || matches[4]),
        prefix: matches[1],
        format: matches[2]
            ? (s) => `${s})`
            : matches[3]
            ? (s) => `#${s}`
            : (s) => `${s}.`
    }
}

/**
 * Extracts the hostname from URL
 * @param url
 * @returns
 */
export function extractHostname(url: string) {
    let hostname
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2]
    } else {
        hostname = url.split('/')[0]
    }

    //find & remove port number
    hostname = hostname.split(':')[0]
    //find & remove "?"
    hostname = hostname.split('?')[0]

    return hostname
}

/**
 * Utility function to get enum's length
 * @param enumeration A typescript's enum
 * @returns
 */
export const getEnumLength = (enumeration: Record<number, string | number>) => {
    return Object.keys(enumeration).filter((v) => isNaN(Number(v))).length
}
