/**
 * Handles authentication as a Mobx object
 */

import { getAuth, User } from 'firebase/auth'
import { computed } from 'mobx'
import {
    idProp,
    Model,
    model,
    modelAction,
    prop,
    _async,
    _await
} from 'mobx-keystone'
import { analyticsCtx } from '../analytics'
import { firebaseAppCtx } from '../firebase'
import { FSSync } from '../firebase/firestore'
import { randomDecision } from '../utils'
import { FSUserData } from './user'

/**
 * Watches for Firebase authentication changes, and populates user
 * with the user data object from Firestore accordingly.
 */
@model('Auth')
export class AuthModel extends Model({
    id: idProp,
    // User data that has been synced
    userData: prop<FSSync<FSUserData> | null>(null),
    // Received the first callback from auth state change?
    // This is useful to determine if we should display spinners
    syncedOnce: prop(false)
}) {
    get auth() {
        return getAuth(firebaseAppCtx.get(this))
    }

    get user() {
        return this.auth.currentUser
    }

    @computed
    get isAuthenticated() {
        return !!this.userData && !!this.user
    }

    onAttachedToRootStore() {
        return this.auth.onAuthStateChanged(this.onAuthStateChanged.bind(this))
    }

    @modelAction
    onAuthStateChanged(newUser: User | null) {
        // Set analytics if available in context
        const analytics = analyticsCtx.get(this)
        if (newUser) {
            this.userData = new FSSync({
                path: `accounts/${newUser.uid}`,
                value: new FSUserData({
                    id: newUser.uid
                })
            })

            if (analytics) {
                analytics.identify(newUser.uid, {
                    name: newUser.displayName,
                    // Backwards compatibility. Deprecated field.
                    displayName: newUser.displayName,
                    email: newUser.email,

                    // Additional deterministic A/B tags
                    test_rand1: randomDecision(`test_rand1.${newUser.uid}`)
                })
            }
        } else if (this.userData) {
            this.userData = null
            if (analytics) analytics.reset()
        }

        this.syncedOnce = true
    }

    signOut() {
        this.auth.signOut()
    }
}
