import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import { Router } from 'next/router'

// Progress bar
import NextNprogress from 'nextjs-progressbar'

// Global imports
import '../style/main.scss'
import { isTouchDevice } from 'lib/common/utils'
import { root } from 'lib/models/context'
import { NotificationsProvider } from '@mantine/notifications'
import { ThemeProvider } from 'lib/theme-provider'

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
    const hasTouchScreen = isTouchDevice()
    analytics.page(url, {
        // Track if page is loaded on a touch device
        hasTouchScreen
    })
    // Need this to identify user's uid with hasTouchScreen
    if (root.auth.user?.uid) {
        analytics.identify(root.auth.user?.uid, {
            hasTouchScreen
        })
    }
})

class MainApp extends App {
    render() {
        const { Component, pageProps } = this.props

        return (
            <>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, shrink-to-fit=no"
                    />
                    <title>Jenni</title>
                </Head>
                <ThemeProvider>
                    <NotificationsProvider position="top-center">
                        <Component {...pageProps} />
                        <NextNprogress
                            color="#616af3"
                            options={{ showSpinner: false }}
                        />
                    </NotificationsProvider>
                </ThemeProvider>
            </>
        )
    }
}

// Wraps all components in the tree with the data provider
export default MainApp
