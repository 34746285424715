import { FREE_MONTHLY_GEN_WORDS } from '@jenni/common/src/constants'
import { getApps, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectDatabaseEmulator, getDatabase } from 'firebase/database'
import {
    connectFirestoreEmulator,
    getFirestore,
    initializeFirestore
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = process.env.FIREBASE_CONFIG as unknown as {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
}

const isDev = process.env.IS_DEV === 'true'
let fbApp

if (getApps().length > 0) {
    fbApp = getApps()[0]
} else {
    if (isDev) {
        // Override DB URL
        firebaseConfig.databaseURL = 'http://127.0.0.1:9000/?ns=jenni-staging'
    }

    fbApp = initializeApp(firebaseConfig)

    if (isDev) {
        // https://stackoverflow.com/questions/59336720/cant-use-cypress-to-test-app-using-using-firestore-local-emulator
        initializeFirestore(fbApp, { experimentalAutoDetectLongPolling: true })
        console.log('Initializing emulator mode')
        connectAuthEmulator(getAuth(), 'http://localhost:9099/')
        connectDatabaseEmulator(getDatabase(), '127.0.0.1', 9000)
        connectFirestoreEmulator(getFirestore(), 'localhost', 8080)
        connectFunctionsEmulator(getFunctions(), 'localhost', 5001)

        if (typeof window !== 'undefined') {
            const remoteConfig = getRemoteConfig(fbApp)
            // During development, it's recommended to set a relatively low minimum fetch interval. See Throttling for more information.
            remoteConfig.settings.minimumFetchIntervalMillis = 1 * 1000
        }
    }

    if (typeof window !== 'undefined') {
        /**
         * Set default values for remote config
         */
        const remoteConfig = getRemoteConfig(fbApp)
        remoteConfig.defaultConfig = {
            test_citation: false,
            test_free_student_limit: FREE_MONTHLY_GEN_WORDS,
            test_transform: false,
            test_use_grammarly: false,
            test_editor_first: false,
            test_dyn_wake: false,
            test_daily_words: false
        }
        // Fetch once upon initialization
        fetchAndActivate(remoteConfig)
    }
}

export const app = fbApp
export const auth = getAuth(app)
export const rtb = getDatabase(app)
export const database = getFirestore(app)
export const functions = getFunctions(app)
