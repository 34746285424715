import { AuthModel } from '@jenni/common/src/auth/auth'
import { model, Model, prop } from 'mobx-keystone'
import { JDoc } from '../editor/jdoc'

/**
 * Root domain store.
 * Stores business logic, does not know about UI.
 */
@model('DomainRoot')
export class DomainRoot extends Model({
    auth: prop<AuthModel>(),
    jdoc: prop<JDoc | undefined>(undefined).withSetter()
}) {}
