/**
 * Creates React contexts for data store
 */
import { analyticsCtx } from '@jenni/common/src/analytics'
import { AuthModel } from '@jenni/common/src/auth/auth'
import { Prices } from '@jenni/common/src/auth/prices'
import { firebaseAppCtx } from '@jenni/common/src/firebase'
import { FSSync } from '@jenni/common/src/firebase/firestore'
import { registerRootStore } from 'mobx-keystone'
import { createContext, useContext } from 'react'
import { DisplayStore } from './display-store'
import { DomainRoot } from './domain/root'
import { app } from './firebase'

/**
 * Global default contexts
 */

// Wrap analytics to segment
analyticsCtx.setDefault({
    async track(data) {
        analytics.track(data.event, data.properties, {
            context: data.context
        })
    },
    async identify(userId, traits) {
        analytics.identify(userId, traits)
    },
    reset() {
        analytics.reset()
    }
})
// Wrap Firebase
firebaseAppCtx.setDefault(app)

/**
 * Global models
 */
export const root = new DomainRoot({
    auth: new AuthModel({})
})
registerRootStore(root)

// Setup prices
export const prices = new FSSync<Prices>({
    path: 'stripeMetadata/prices',
    value: new Prices({})
})
registerRootStore(prices)

const displayStore = new DisplayStore({})

export const StoresContext = createContext({
    root,
    auth: root.auth,
    prices,
    displayStore
})

export const useStores = () => useContext(StoresContext)
