import { Timestamp } from 'firebase/firestore'
import { ModelPropTransform } from 'mobx-keystone'

export interface JSONTimestamp {
    isNull?: true
    seconds: number
    nanoseconds: number
}

export const timestampTransform: ModelPropTransform<
    JSONTimestamp,
    Timestamp | null
> = {
    transform({ originalValue, cachedTransformedValue, setOriginalValue }) {
        return (
            cachedTransformedValue ??
            new Timestamp(originalValue.seconds, originalValue.nanoseconds)
        )
    },

    untransform({ transformedValue, cacheTransformedValue }) {
        if (transformedValue instanceof Timestamp) {
            cacheTransformedValue()
        }
        if (!transformedValue) {
            return { seconds: 0, nanoseconds: 0, isNull: true }
        }
        return {
            seconds: transformedValue.seconds,
            nanoseconds: transformedValue.nanoseconds
        }
    }
}
